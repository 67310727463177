export const formatAddressForDisplay = ({address}) => {
  let formattedAddress = '';

  if (address.address_one) {
    formattedAddress += `${address.address_one}, `;
  }

  formattedAddress += `${address.city}, ${address.state.code}`;

  return formattedAddress;
};