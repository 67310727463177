import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { closeModal } from 'src/actions/modalActions';
import FindRewardSource from 'src/components/employers/rewardSources/FindRewardSource';

function EmployersFindRewardSourceModal({ ...props }) {
  const dispatch = useDispatch();

  function hide() {
    dispatch(closeModal());
  };

  return (
    <div>
      <Modal size={"lg"} show={true} onHide={e => hide()} fullscreen="md-down">
        <Modal.Header className="px-4" closeButton>
          <div>Find rewardable business</div>
        </Modal.Header>
        <Modal.Body>
          <FindRewardSource hideModal={hide} {...props} />
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default EmployersFindRewardSourceModal;