import { Alert, Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import axios from 'axios';

import { useMediumMediaQuery } from 'src/hooks/responsiveMediaQueries';
import AutocompleteLocationInput from 'src/components/forms/AutocompleteLocationInput';
import Loading from 'src/components/Loading';
import RewardSourceCardEntry from './RewardSourceCardEntry';
import 'src/css/employers/rewardSources/FindRewardSource.css';

const FindRewardSource = ({ handleSelectRewardSource }) => {
  const [rewardSources, setRewardSources] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [findRewardSourceFormData, setFindRewardSourceFormData] = useState({ what_search_text: '', where_search_text: ''});

  const upToMedium = useMediumMediaQuery();

  const handleSubmit = (e) => {
    e.preventDefault();
    loadRewardSources();
  }

  const loadRewardSources = (e) => {
    setIsLoading(true);
    axios.get('/api/v1/reward_sources/approved', { params: findRewardSourceFormData})
      .then((response) => {
        setRewardSources(response.data);
      })
      .catch((error) => {
        setRewardSources([]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    loadRewardSources();
  }, []);

  return (
    <div className="mx-1 mx-md-3">
      <Row className="mt-3 mb-2 align-items-center">
        <Col>
          <Form onSubmit={(e) => handleSubmit(e)}>
            { upToMedium ?
              <>
                <Form.Control className="mb-2" type="text" placeholder="Name or keywords" value={findRewardSourceFormData.what_search_text} onChange={e => setFindRewardSourceFormData({ ...findRewardSourceFormData, what_search_text: e.target.value })} />
                <AutocompleteLocationInput formData={findRewardSourceFormData} setFormData={setFindRewardSourceFormData} withFloatingLabel={false} placeholder={'City, state, zip'} />
                <Button type="submit" className="standard-btn mt-2">Search</Button>
              </>
            :
              <InputGroup className="d-flex flex-wrap">
                <Form.Control type="text" placeholder="Name or keywords" value={findRewardSourceFormData.what_search_text} onChange={e => setFindRewardSourceFormData({ ...findRewardSourceFormData, what_search_text: e.target.value })} />
                <AutocompleteLocationInput formData={findRewardSourceFormData} setFormData={setFindRewardSourceFormData} withFloatingLabel={false} placeholder={'City, state, zip'} />
                <Button type="submit" className="standard-btn">Search</Button>
              </InputGroup>
            }

          </Form>
        </Col>
      </Row>

      <Row className="mb-2">
        <small><strong>{rewardSources.length}</strong>{rewardSources.length === 1 ? ' business' : ' businesses'}</small>
      </Row>

      {isLoading ?
        <Loading />
      :
        <>
          {rewardSources.length === 0 ?
            <Row className="mt-3">
              <Col>
                <Alert variant="warning">There are no matching businesses.</Alert>
              </Col>
            </Row>
            :
            <div className="reward-sources-container pe-0 pe-md-3">
              {rewardSources.map((rewardSource) => (
                <div key={"reward-source-" + rewardSource.id} to="#" className="reward-source-entry-link" onClick={(e) => { handleSelectRewardSource(e, rewardSource) } }>
                  <RewardSourceCardEntry rewardSource={rewardSource} showSelectButton={true} handleSelect={handleSelectRewardSource} displayAsTableEntry={true} />
                </div>
              ))}
            </div>
          }
        </>
      }
    </div>
  );
}

export default FindRewardSource;