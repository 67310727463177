import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import LogoPlaceholderPath from '../../images/logo-placeholder-500x500.png';

const RewardSourceTableEntry = ({ rewardSource }) => {

  return (
    <tr>
      <td>{rewardSource.id}</td>
      <td><img className="md-icon-img" src={rewardSource.icon ? rewardSource.icon : LogoPlaceholderPath} /></td>
      <td>{rewardSource.name}</td>
      <td>{rewardSource.region?.name}</td>
      <td className="text-center">
        {rewardSource.approved ?
          <i className="fa fa-check text-success"></i>:
          <i className="fa fa-times text-danger"></i>
        }
      </td>
      <td className="text-end">
        <Button variant="secondary" as={Link} to={`/admin/reward_sources/${rewardSource.id}/edit`}>
          <i className="fa fa-edit" />
        </Button>
      </td>
    </tr>
  );
}

export default RewardSourceTableEntry;