import { Card, Row, Col} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import React from 'react';

import { formatAddressForDisplay } from 'src/utils/AddressUtils';
import TruncatedText from 'src/components/generic/TruncatedText';
import 'src/css/employers/rewardSources/RewardSourceCardEntry.css';

const RewardSourceCardEntry = ({ rewardSource, displayAsTableEntry = false }) => {

  return (
    <Card className={displayAsTableEntry ? 'display-as-table-entry' : 'display-as-card'}>
      <Row className="align-items-center">
        <Col className="d-flex">
          {rewardSource?.icon &&
            <Col xs="auto" className="d-flex justify-content-center flex-column align-items-center">
              <img className="md-icon-img" src={rewardSource.icon} />
            </Col>
          }
          <Col className="d-flex flex-column ms-2">
            <div>
              {rewardSource.url ?
                <Link className="standard-link" to={rewardSource.url} onClick={(e) => e.stopPropagation()} target="_blank">{rewardSource.name}{' '}<i className="fa-solid fa-arrow-up-right-from-square fa-xs"></i></Link>
                :
                <span className="fw-bold">{rewardSource.name}</span>
              }
            </div>
            {rewardSource.address && <div><small>{formatAddressForDisplay({address: rewardSource.address})}</small></div> }
            <div><small><TruncatedText text={rewardSource.description} /></small></div>
          </Col>
        </Col>
      </Row>
    </Card>
  );
}

export default RewardSourceCardEntry;